.Left {
	width: 1.5rem;
	height: 2px;
	border-bottom: 2px solid #adb5bd;
}

.Right {
	flex-grow: 1;
	height: 2px;
	border-bottom: 2px solid #adb5bd;
}