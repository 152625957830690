.LinkBtn,
.LinkBtn:active,
.LinkBtn:focus {
	padding: 0 !important;
	color: rgba(255,255,255,0.75) !important;
	text-decoration: none !important;
	outline: none !important;
  box-shadow: none !important;
}

.LinkBtn:hover{
	color: #fff !important;
}
