.Order {
	padding: 2rem;
	border-radius: 1rem;
	border:  1px solid #C8DDCC;
	margin-bottom: 1rem;
}

.Order:last-child {
	margin-bottom: 0;
}

.Header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #C8DDCC;
	padding-bottom: 0.5rem;
}