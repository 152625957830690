.PrimaryCTA {
	background: #697A57 !important;
	border: 2px solid #697A57 !important;
	color: #fff;
	padding: 0.5rem 1.5rem !important;
	border-radius: 0.4rem !important;
	white-space: nowrap;
}

.PrimaryCTA:hover {
	border-color: #5F6F4E !important;
}
