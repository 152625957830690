.ListItem {
	margin-right: 1.5rem;
}

.ListItem:last-child{
	margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .Navbar .ListItem{
  	margin-right: 0;
  	margin-bottom: 0.5rem;
  	text-align: center;
  }
}