.NavLink {
	padding: 0 !important;
	color: rgba(255,255,255,0.75) !important;
	text-decoration: none !important;
}

.NavLink:hover {
	color: #fff !important;
}

.NavLink.Active {
	color: #fff !important;
}